import componentsCmsMegaMenu from "/var/www/domains/content/config/app/components/cms/megaMenu"
import componentsCmsMegaMenuL1Desktop from "/var/www/domains/content/config/app/components/cms/megaMenuL1Desktop"
import componentsCmsMegaMenuL1Mobile from "/var/www/domains/content/config/app/components/cms/megaMenuL1Mobile"
import componentsCmsMegaMenuL2Mobile from "/var/www/domains/content/config/app/components/cms/megaMenuL2Mobile"
import componentsCmsMegaMenuL3Desktop from "/var/www/domains/content/config/app/components/cms/megaMenuL3Desktop"
import componentsCmsMegaMenuL3Mobile from "/var/www/domains/content/config/app/components/cms/megaMenuL3Mobile"
import componentsCmsMegaMenuVisualSelector from "/var/www/domains/content/config/app/components/cms/megaMenuVisualSelector"
import componentsCmsProductRecommendations from "/var/www/domains/content/config/app/components/cms/productRecommendations"
import componentsCmsPromoBar from "/var/www/domains/content/config/app/components/cms/promoBar"
import componentsCmsSecondaryNav from "/var/www/domains/content/config/app/components/cms/secondaryNav"
import componentsCmsSection from "/var/www/domains/content/config/app/components/cms/section"
import componentsCmsSharedButton from "/var/www/domains/content/config/app/components/cms/sharedButton"
import pagesCms from "/var/www/domains/content/config/app/pages/cms"
    
export default {"components":{"cms":{"megaMenu":componentsCmsMegaMenu,"megaMenuL1Desktop":componentsCmsMegaMenuL1Desktop,"megaMenuL1Mobile":componentsCmsMegaMenuL1Mobile,"megaMenuL2Mobile":componentsCmsMegaMenuL2Mobile,"megaMenuL3Desktop":componentsCmsMegaMenuL3Desktop,"megaMenuL3Mobile":componentsCmsMegaMenuL3Mobile,"megaMenuVisualSelector":componentsCmsMegaMenuVisualSelector,"productRecommendations":componentsCmsProductRecommendations,"promoBar":componentsCmsPromoBar,"secondaryNav":componentsCmsSecondaryNav,"section":componentsCmsSection,"sharedButton":componentsCmsSharedButton}},"pages":{"cms":pagesCms}}
